.navbar {
    left: 50%;
    bottom: 20px;
    width: 360px;
    height: 80px;
    z-index: 10000;
    position: fixed;
    background: #fff;
    border-radius: 20px;
    transform: translate(-50%, 0);
    box-shadow: 0px 0px 6px 1px #9696df;
    ul {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        li {
            height: 40px;
            width: 40px;
            position: relative;
            svg {
                top: 50%;
                left: 50%;
                z-index: 11;
                color: #000;
                font-size: 25px;
                position: absolute;
                transform: translate(-50%, -50%);
            }
            a {
                top: 0;
                left: 0;
                z-index: 10;
                width: 40px;
                height: 40px;
                position: absolute;
                border-radius: 14px;
                transform: translate(-0, -0);
            }
        }
        .active {
            a {
                background: #74aafb75;
            }
        }
    }
}