.star_directory_info {
    h1 {
        font-size: 25px;
        margin-top: 35px;
        font-weight: bold;
        text-align: center;
    }
    p {
        font-size: 20px;
        margin-top: 35px;
        line-height: 26px;
    }
}