.services {
    // margin-top: 40px;
    .services__title {
        font-size: 30px;
        font-weight: bold;
    }
    .services__active_service {
        margin-top: 20px;
        h2 {
            font-size: 21px;
            font-weight: bold;
        }
        p {
            font-size: 18px;
            margin-top: 35px;
            text-align: center;
        }
    }
    .service {
        width: 100%;
        display: flex;
        height: 440px;
        max-width: 300px;
        min-width: 300px;
        margin-top: 25px;
        padding: 30px 25px;
        text-align: center;
        align-items: center;
        border-radius: 10px;
        flex-direction: column;
        justify-content: space-between;
        background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
        h1 {
            font-size: 23px;
            font-weight: bold;
        }
        p {
            font-size: 14px;
        }
        img {
            width: 150px;
            height: 100px;
        }
        .service_date {
            width: 100%;
            display: flex;
            font-weight: bold;
            align-items: center;
            justify-content: space-between;
            p {
                margin-top: 0px;
            }
        }
        .service__description {
            margin-top: 15px;
        }
        .package__description {
            max-height: 42px;
        }
        
        .package_price {
            width: 100%;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 19px;
                font-weight: bold;
            }
        }
        button {
            width: 100%;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            padding: 18px 26px;
            border-radius: 127px;
            background: linear-gradient(135deg,#8c88ff 0%,#74aafb 100%);
        }
    }
}