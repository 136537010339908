.chat {
    .chat__header {
        height: 50px;
        display: flex;
        align-items: center;
        background: linear-gradient(135deg,#8c88ff 0%,#74aafb 100%);
        justify-content: center;
        border-radius: 0 0 15px 15px;
        p {
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
    }
    .chat__messages {
        display: grid;
        padding: 0 8px;
        overflow-y: auto;
        max-height: calc(100vh - 110px);
    }
    .chat__images {
        width: 100%;
        bottom: 0;
        z-index: 998;
        height: 100px;
        display: flex;
        position: fixed;
        background: #fff;
        align-items: center;
        justify-content: center;
    }

    .uploaded_images {
        width: 100%;
        height: 100px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
    }
    
    .chat__control_block {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        background: #fff;

        .input_handler {
            gap: 15px;
            display: flex;
            padding: 10px 18px;
            align-items: center;
            justify-content: center;
            svg {
                width: 20px;
                height: 20px;
                color: #000;
            }
            input {
                width: 100%;
                height: 30px;
                padding: 12px;
                border-radius: 12px;
                border: 1px solid #b3b3b3;
            }
            .send_icon {
                svg {
                    color: #F24259;
                }
            }
            .message_block {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 15px;
            }
        }
        .record_handler {
            display: flex;
            padding: 15px 18px;
            align-items: center;
            justify-content: space-between;
            svg {
                width: 20px;
                height: 20px;
                color: #000;
            }

            .audio_record {
                display: flex;
                align-items: center;
                gap: 15px;
                .audio_record__header {
            
                }
                .sound_wave {
                    display: none;
                }
            }
        }
    }
}