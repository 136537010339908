.home_page__blogs {
    display: grid;
    margin-top: 45px;
    grid-gap: 13px 15px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    a {
        width: 100%;
        height: 100%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            border-radius: 7px;
        }
        p {
            bottom: 5px;
            opacity: 0.9;
            height: 50px;
            padding: 5px;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            position: absolute;
            background-color: #504f4f;
            display: flex;
            align-items: center;
            text-align: left;
            width: 100%;
        }
    }
}