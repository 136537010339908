.chats {
    margin-top: 40px;
    .chats__title {
        font-size: 30px;
        font-weight: bold;
    }
    .chats__toogle_menu {
        width: 100%;
        height: 60px;
        display: flex;
        margin: 0 auto;
        margin-top: 15px;
        text-align: center;
        border-radius: 75px;
        align-items: center;
        background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
        button {
            width: 100%;
            height: 100%;
            color: #000;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            border-radius: 75px;
            font-optical-sizing: auto;
            padding: 0px, 46px, 0px, 46px;
            font-family: "Montserrat", sans-serif;
            background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
        }
        .active {
            color: #fff;
            background: #9696df;
        }
    }
    .active_chats {
        display: grid;
        grid-gap: 15px;
        margin-top: 13px;
        grid-template-rows: auto;
        .chats__chat {
            color: #fff;
            padding: 15px 10px;
            border-radius: 10px;
            background: linear-gradient(135deg, #8c88ff 0%, #74aafb 100%);

            .chats__chat__up_block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    font-weight: bold;
                    font-size: 18px;
                }
                svg {
                    font-size: 35px;
                    border-radius: 15px;
                }
            }
            p {
                font-size: 14px;
                font-weight: bold;
            }
        }
    }
    .chats__text {
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
    }
    .chats__btn {
        margin-top: 70px;
        button {
            max-width: none;
        }
    }
}