.profile {
    .profile__info {
        gap: 10px;
        height: 270px;
        display: flex;
        margin-top: 90px;
        padding: 3px 20px;
        position: relative;
        border-radius: 12px;
        flex-direction: column;
        background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
        .profile__info_name {
            h1 {
                font-size: 21px;
                font-weight: bold;
                text-align: center;
            }
        }
        .profile__info_text {
            font-size: 17px;
            margin-top: 70px;
            text-align: center;
        }
        .profile__active_services {
            width: 100%;
            height: 50px;
            display: flex;
            margin-top: 15px;
            background: #fff;
            border-radius: 30px;
            align-items: center;
            align-content: center;
            justify-content: center;
            padding: 4px 4px 4px 20px;
            border: 1px solid #74aafb;
            justify-content: space-between;

            .profile__active_services__name {
                color: #000;
                font-size: 17px;
            }

            .profile__active_services__counts {
                width: 42px;
                height: 100%;
                color: #fff;
                display: flex;
                font-size: 20px;
                border-radius: 50%;
                align-items: center;
                background: #74aafb;
                justify-content: center;
            }
        }
        .profile__info__logo_block1 {
            display: flex;
            align-items: center;
            justify-content: center;

            left: 50%;
            top: -55px;
            width: 110px;
            height: 110px;
            margin: 0 auto;
            border-radius: 50%;
            position: absolute;
            transform: translate(-50%, 0);
            background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
            .profile__info__logo_block2 {
                width: 95px;
                height: 95px;
                display: flex;
                margin: 0 auto;
                border-radius: 50%;
                background: #fff;
                align-items: center;
                justify-content: center;
                .profile__info__logo_block3 {
                    width: 85px;
                    height: 85px;
                    display: flex;
                    border-radius: 50%;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
                    .profile__info__logo {
                        width: 70px;
                        height: 70px;
                        display: flex;
                        margin: 0 auto;
                        border-radius: 50%;
                        background: #fff;
                        align-items: center;
                        justify-content: center;
                        svg {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
    .profile__menu {
        .profile__menu__item {
            width: 100%;
            height: 50px;
            font-size: 17px;
            margin-top: 15px;
            background: #fff;
            padding: 10px 18px;
            border-radius: 15px;
            align-content: center;
            border: 1px solid #74aafb;
            justify-content: space-between;

            p {
                color: #000;
            }
        }
    }
}