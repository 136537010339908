.support {
    margin-top: 40px;
    .support__title {
        font-size: 30px;
        font-weight: bold;
    }
    .support__menu_items {
        display: grid;
        grid-gap: 10px;
        margin-top: 20px;
        grid-template-rows: auto;
        .support__menu_item {
            padding: 15px 0;
            border-bottom: 1px solid #bdbcbc;
            .support__menu__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            .support__menu__description {
                font-size: 14px;
                margin-top: 18px;
                line-height: 18px;
            }
        }
    }
    .support__questions {
        margin-top: 40px;
        h2 {
            font-size: 22px;
            font-weight: bold;
        }
        .support__questions__contacts {
            display: grid;
            grid-gap: 18px;
            margin-top: 15px;
            grid-template-rows: auto;
            .support__questions__contacts__contact {
                gap: 15px;
                display: flex;
                align-items: center;
                justify-content: left;
            }
        }
    }
    .support__social_networks {
        gap: 12px;
        display: flex;
        margin-top: 25px;
        align-items: center;
        justify-content: left;
        .support__social_networks__network {}
    }
}