.main_layout {
    padding: 15px;
    .main_layout__content {
        margin-top: 20px;
        padding-bottom: 120px;
    }
}

.logo_layout {
    padding: 15px;
}