.blog {
    padding: 15px;
    .blog__img {
        width: 100%;
    }
    .blog__content {
        margin-top: 25px;
        h1 {
            font-size: 20px;
            font-weight: bold;
        }
        ul {
            margin-bottom: 10px;
            margin-top: 10px;
            &:first-child {
                margin-top: 0px;
            }
            &:last-child {
                margin-bottom: 0px;
            }
            li {
                font-size: 20px;
                font-family: Lato, sans-serif;
            }
        }
        p {
            font-size: 15px;
            line-height: 148.3%;
            margin-top: 10px;
        }
    }
}