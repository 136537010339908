.welcome_page {
    padding: 0 20px;
    padding-top: 30px;
    .welcome_page__logo {
        text-align: center;
    }
    .welcome_page__description {
        text-align: center;
        p {
            font-size: 14px;
        }
    }
    .welcome_info__services {
        margin-top: 15px;
        ul {
            display: grid;
            grid-gap: 19px;
            justify-content: center;
            li {
                gap: 14px;
                display: flex;
                align-items: center;
                justify-content: left;
                p {
                    color: black;
                    font-size: 15px;
                    font-weight: 600;
                    text-align: left;
                }
                .image {
                    width: 40px;
                    height: 40px;
                    min-width: 40px;
                    min-height: 40px;
                    position: relative;
                    border-radius: 50%;
                    background-color: #9696df;
                    img {
                        top: 50%;
                        left: 50%;
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        transform: translate(-50%, -50%);                    
                    }
                }
            }
        }
    }
    .welcome_page__btn {
        margin-top: 30px;
        text-align: center;
    }
}


// #9696df