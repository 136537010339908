.stars_directory {
    .stars_directory__title {
        font-size: 30px;
        font-weight: bold;
    }
    .stars_directory__links {
        display: grid;
        margin-top: 15px;
        a {
            color: #000;
            font-size: 20px;
            padding: 20px 0;
            font-weight: bold;
            border-bottom: 1px solid #f3f3f3;
        }
    }
}