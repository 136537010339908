.service_pay {
    margin-top: 40px;
    .service_pay__title {
        font-size: 30px;
        font-weight: bold;
    }
    .service_pay__service_info {
        margin-top: 10px;
        h2 {
            font-size: 20px;
            font-weight: bold;
        }
    }
    .service_pay__promocode {
        margin-top: 60px;
        form {
            gap: 10px;
            padding: 0px 0px 0px 4px;
            display: flex;
            margin-top: 4px;
            border-radius: 127px;
            justify-content: center;
            border: 1px solid #888888;
            input {
                height: 40px;
                width: 100%;
                border-radius: 128px 0 0 128px;
            }
            button {
                width: 110px;
                height: 40px;
                color: #fff;
                padding: 0 5px;
                border-radius: 15px;
                background: #1f87ff;
            }
        }
    }
    .service_pay__personal_info {
        gap: 15px;
        display: flex;
        margin-top: 40px;
        span {
            color: #1f87ff;
            font-weight: bold;
        }
    }
    .service_pay__button_block {
        
    }

    .service_pay__btn {
        margin-top: 50px;
        text-align: center;
        button {
            max-width: 100%;
            width: 100%;
            height: 59px;
            border: none;
            color: #fff;
            font-size: 16px;
            border-radius: 75px;
            font-family: inherit;
            background: #9696df;
        }
    }
}