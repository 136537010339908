.messages {
    .chat__receiver_message {
        float: left;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #aaaaff;
        padding: 10px 10px 6px;
        
        p {
            &:first-child {
                color: #fff;
                font-size: 14px;
                font-weight: 450;
                line-height: 20px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
            &:last-child {
                float: left;
                color: #fff;
                font-size: 12px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .chat__send_message {
        float: right;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #e2ffc9;
        padding: 10px 10px 6px;
        
        p {
            &:first-child {
                color: #000;
                font-size: 14px;
                font-weight: 450;
                line-height: 20px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
            &:last-child {
                float: right;
                color: #000;
                font-size: 12px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .chat__send_message_img {
        float: right;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #e2ffc9;
        padding: 10px 10px 6px;
        
        p {
            float: right;
            color: #000;
            margin-top: 5px;
            font-size: 12px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .chat__receiver_message_img {
        float: left;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #aaaaff;
        padding: 10px 10px 6px;
        
        p {
            float: left;
            color: #fff;
            margin-top: 5px;
            font-size: 12px;
            font-style: normal;
            font-optical-sizing: auto;
            font-family: "Montserrat", sans-serif;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
    .chat__send_message_file {
        float: right;
        display: grid;
        text-align: left;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #e2ffc9;
        padding: 10px 10px 6px;
        button {
            color: #333;
            margin-top: 5px;
            text-align: left;
            background: none;
        }
        p {
            &:last-child {
                float: right;
                color: #000;
                margin-top: 5px;
                font-size: 12px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .chat__receiver_message_file {
        float: left;
        display: grid;
        text-align: left;
        margin-top: 10px;
        max-width: 320px;
        border-radius: 10px;
        background: #aaaaff;
        padding: 10px 10px 6px;
        button {
            color: #333;
            margin-top: 5px;
            text-align: left;
            background: none;
        }
        p {
            &:last-child {
                float: left;
                color: #000;
                margin-top: 5px;
                font-size: 12px;
                font-style: normal;
                font-optical-sizing: auto;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}

.chat__empty {
    top: 40%;
    left: 50%;
    margin: 0 auto;
    position: absolute;
    transform: translate(-50%, 0);
}