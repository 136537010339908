.consultations {
    margin-top: 50px;
    .consultations__title {
        p {
            width: 270px;
            font-size: 25px;
            font-weight: bold;
        }
    }
}

.packages_filter {
    .packages_filter__button {
        display: flex;
        color: #fff;
        padding: 15px 20px;
        margin-top: 15px;
        border-radius: 127px;
        background: #9696df;
        justify-content: space-between;
    
        p {
            font-size: 20px;
        }
        svg {
            font-size: 18px;
        }
    }
    .packages_filter__items {
        margin-top: 20px;
        ul {
            display: grid;
            text-align: center;
            grid-template-rows: auto;

            li {
                padding: 20px 0;
                font-size: 20px;
            }
        }
    }
}

.packages {
    gap: 10px;
    display: flex;
    max-width: 100%;
    margin-top: 25px;
    overflow-y: hidden;
    .packages__package {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        text-align: center;
        max-width: 280px;
        min-width: 280px;
        height: 530px;
        padding: 30px 25px;
        border-radius: 20px;
        background: linear-gradient(180deg, #efefff 0%, #e7efff 100%);
        h1 {
            font-size: 23px;
            font-weight: bold;
        }
        img {
            width: 205px;
            height: 125px;
        }
        .package__description {
            max-height: 42px;
        }
        
        .package_price {
            width: 100%;
            display: flex;
            justify-content: space-between;

            p {
                font-size: 19px;
                font-weight: bold;
            }
        }
        button {
            width: 100%;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            padding: 18px 26px;
            border-radius: 127px;
            background: linear-gradient(135deg,#8c88ff 0%,#74aafb 100%);
        }
        a {
            font-size: 16px;
            font-weight: 500;
        }
    }
}