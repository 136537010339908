.main_btn {
    width: 100%;
    height: 59px;
    border: none;
    color: #fff;
    font-size: 16px;
    max-width: 234px;
    border-radius: 75px;
    font-family: inherit;
    background: #9696df;
}